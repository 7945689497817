import "./css/Card.scss"

import BasicCard from './BasicCard'
import TableCard from './TableCard'
import TimelineCard from './TimelineCard'

export default function Card({ cardData, payload, cardClassName }) {
    return (
        <div className={`card ${cardClassName}`}>
            <div className="card_header">
                <div className="iconWrapper">
                    {cardData.icon}
                </div>
                <span className="title">{cardData.title}</span>
                <span className="timeframe">{cardData.subtitle}</span>
            </div>
            {
                cardData === null ? 'loading .. ':
                {
                    'BasicCard': <BasicCard cardData={cardData} />,
                    'TableCard': <TableCard cardData={cardData} payload={payload} />,
                    'TimelineCard': <TimelineCard cardData={cardData} payload={payload} />
                }[cardData.ctype]
            }
        </div>
    )
  }