import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import logout_icon from '../assets/icons/darkblue/logout.png';
import { useTranslation } from "react-i18next";

// Firebase imports
import { signOut } from 'firebase/auth';
import { auth, firestore } from '../context/FirebaseConfig';
import { doc, setDoc} from 'firebase/firestore'

export default function Navbar({ timeframeString, setTimeframe, userCompany, userLanguage, setUserLanguage }) {
    const { i18n, t } = useTranslation();

    const setDay = () => { setTimeframe("day") }
    const setWeek = () => { setTimeframe("week") }
    const setMonth = () => { setTimeframe("month") }
    const setAllTime = () => { setTimeframe("all") }

    // // Fetching user information
    const { currentUser } = useContext(AuthContext);

    // Handle language change
    
    const handleLanguageChange = async (e) => {
        const selectedLanguage = e.target.value;
        setUserLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        // Update the language code for the user
        await setLanguageCode(currentUser, selectedLanguage);
    };
    const setLanguageCode = async (user, languageCode) => {
        if (user) {
            const userRef = doc(firestore, 'users', user.uid);
            await setDoc(userRef, { languageCode: languageCode }, { merge: true });
        }
    };
    
    return (
        <div className="navbar">
            <div className='title-users-logout'>
                <h1 className='title'>{userCompany} {t("sidebar_dashboard")}</h1>
                <div className='user'>
                    <span>{t("navbar_hello")}, {currentUser.displayName}</span>
                    <img src={currentUser.photoURL} alt="" className='user_photo_img'/>
                    <button onClick={() => signOut(auth)} style={{display:"none"}} id='logout_icon'/>
                    <select className="language-dropdown"value={userLanguage} onChange={handleLanguageChange}>
                        <option value="en" >EN</option>
                        <option value="de">DE</option>
                    </select>
                    <label htmlFor='logout_icon'>
                        <img src={logout_icon} alt="logout icon" className='logout_icon_img'/>
                    </label>
                </div>
            </div>
            <div className="timeframe-dropdown">
                <div className="dropdown-splitbutton">
                    <button className="dropdown-splitbutton-text">{t("timeframe_title")}:</button>
                    <button className="dropdown-splitbutton-button">{timeframeString}</button>
                </div>
                <div className="dropdown-content">
                    <a onClick={setDay}>{t("timeframe_today")}</a>
                    <a onClick={setWeek}>{t("timeframe_week")}</a>
                    <a onClick={setMonth} >{t("timeframe_month")}</a>
                    <a onClick={setAllTime}>{t("timeframe_all")}</a>
                </div>
            </div>
            
        </div>
    )
}