import "../styles/home.scss"
import React from 'react';
import { AuthContext } from '../context/AuthContext';
import { firestore } from "../context/FirebaseConfig";
import { collection, query, where, doc, getDoc, getDocs} from "firebase/firestore";
import { useTranslation } from "react-i18next";

// Importing custom pages
import Navbar from '../components/Navbar';
import Insights from '../components/Insights';
import Sidebar from '../components/Sidebar';
import LoadingScreen from "../components/LoadingScreen";
// Importing custom components


const Home = () => {
    const { i18n, t } = useTranslation();

    const [timeframe, setTimeframe] = React.useState("day")
    const timeframeString = (
        timeframe === "day" ? t("timeframe_today") :
        timeframe === "week" ? t("timeframe_week") :
        timeframe === "month" ? t("timeframe_month") :
        timeframe === "all" ? t("timeframe_all") :
        t("timeframe_all")
    )
    
    const { currentUser } = React.useContext(AuthContext);
    const [ userLanguage, setUserLanguage ] = React.useState('en');
    const [ userCompany, setUserCompany ] = React.useState('');
    const [ device, setDevice ] = React.useState(null)
    const fetchUserInfo = async (uid) => {
        // Fetch user company & language
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return;
        }
        const company = userDocSnap.data().company;
        setUserLanguage(userDocSnap.data().languageCode);
        i18n.changeLanguage(userDocSnap.data().languageCode);

        // Fetch company display name
        const clientDocSnap = await getDoc(doc(firestore, "clients", company));
        if (clientDocSnap.exists()) {
            setUserCompany(clientDocSnap.data().company_displayname)
            setDevice(clientDocSnap.data().dashboard_device)
        }
    }

    // Reload user info on auth change
    React.useEffect(() => {
        if (currentUser && currentUser.uid) {
            fetchUserInfo(currentUser.uid);
        }
    }, [ currentUser ]);

    return (
        <div className="home">
            <Sidebar />
            <div className="home--right">
                <Navbar 
                    timeframeString={timeframeString}
                    setTimeframe={setTimeframe}
                    userCompany={userCompany}
                    userLanguage={userLanguage}
                    setUserLanguage={setUserLanguage}
                />
                { device === null ? 
                    <LoadingScreen loading={t("loading")} fullscreen={false} message={t("device_not_found_error")} /> :
                    <Insights 
                        timeframe={timeframe}
                        timeframeString={timeframeString}
                        device={device}
                    />
                }
            </div>
        </div>
    )
}

export default Home