import { useTranslation } from "react-i18next";
import "../styles/home.scss"
import arc_logo from '../assets/logos/arc_logo_blue.png'
import { Link } from "react-router-dom"
import React, { useContext } from 'react';  

// Importing icons
import user_icon from '../assets/icons/gray/user.svg';
import document_icon from '../assets/icons/gray/document.svg';
import dashboard_icon from '../assets/icons/gray/dashboard.svg';
import live_icon from '../assets/icons/gray/live.svg';
import overview_icon from '../assets/icons/gray/overview.svg';
import trade_icon from '../assets/icons/gray/trade.svg';
import invoice_icon from '../assets/icons/gray/invoice.svg';
// import reporting_icon from '../assets/icons/gray/reporting.svg';



// imports for TEST 1 //
import { AuthContext } from "../context/AuthContext";
import { firestore } from "../context/FirebaseConfig";
import { doc, updateDoc, arrayUnion, increment, getDoc, setDoc } from 'firebase/firestore';


export default function Sidebar() {
    // translation tools
    const { i18n, t } = useTranslation();

    // Below are tests for still unused navigation bars - TEST 1 //
    const { currentUser } = useContext(AuthContext);
    const handleClick = async (event, linkName) => {
        event.preventDefault();
        try {
          const docRef = doc(firestore, 'tests', 'button-clicking-tests-v1');
          const docSnapshot = await getDoc(docRef);
      
          const fieldName = `${linkName}.${currentUser.displayName} ${currentUser.uid}`;
      
          if (!docSnapshot.exists()) {
            // Create the document with initial data
            await setDoc(docRef, {
              [fieldName]: 1,
            });
          } else {
            // Update the existing document
            await updateDoc(docRef, {
              [fieldName]: increment(1),
            });
          }
        } catch (error) {
          console.log('Error updating click count:', error);
        }
      };

    return (
        <div className="sidebar">
            <img src={arc_logo} className="sidebar--logo" />
            <ul className="sidebar--links">
                <li onClick={(e) => handleClick(e, 'Dashboard')}>
                    <Link to="/">
                        <img src={dashboard_icon} alt="dashboard icon"/>
                        <p>{t("sidebar_dashboard")}</p>
                    </Link>
                </li>
                {/* <li onClick={(e) => handleClick(e, 'Bale Overview')}>
                    <Link to="/">
                        <img src={overview_icon} alt="overview icon"/>
                        <p>{t("sidebar_bales")}</p>      
                    </Link> 
                </li> */}
                <li onClick={(e) => handleClick(e, 'LiveView')}>
                    <Link to="/">
                        <img src={live_icon} alt="live icon"/>
                        <p>{t("sidebar_live")}</p>      
                    </Link>              
                </li>
                {/* <li onClick={(e) => handleClick(e, 'Reporting')}>
                    <Link to="/">
                        <img src={document_icon} alt="document icon"/>
                        <p>{t("sidebar_reporting")}</p>      
                    </Link>   
                </li>
                <li onClick={(e) => handleClick(e, 'Invoicing')}>
                    <Link to="/">
                        <img src={invoice_icon} alt="invoice icon"/>
                        <p>{t("sidebar_invoicing")}</p>
                    </Link>
                </li>
                <li onClick={(e) => handleClick(e, 'Subscriptions')}>
                    <Link to="/">
                        <img src={user_icon} alt="user icon"/>
                        <p>{t("sidebar_subscriptions")}</p>      
                    </Link> 
                </li>
                <li onClick={(e) => handleClick(e, 'Shop')}>
                    <Link to="/">
                        <img src={trade_icon} alt="trade icon"/>
                        <p>{t("sidebar_trade")}</p>      
                    </Link> 
                </li> */}
            </ul>
        </div>
    )
}