import React, { useRef, useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Importing custom pages
import Login from './pages/Login';
import Home from './pages/Home';
import Register from './pages/Register';
import LoadingScreen from './components/LoadingScreen';

// Importing context provider. 
import { AuthContext } from './context/AuthContext';

// Accessing firebase resources initialized in the "firebase.js"
import { auth, firestore, storage } from './context/FirebaseConfig';

function App() {

  // finding the current user
  const { currentUser } = useContext(AuthContext);

  // Creating a protected route to only allow logged-in users to access the dashboard. 
  const ProtectedRoute = ({children}) => {
    if(!currentUser){
      return <Navigate to="/login"/>
    }
    // else
    return children
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<ProtectedRoute><Home auth={auth}/></ProtectedRoute>} /> 
          <Route path="login" element={<Login/>} />
          <Route path="register" element={<Register auth={auth} storage={storage} firestore={firestore}/>} />
          <Route path="loading" element={<ProtectedRoute><LoadingScreen /></ProtectedRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
