import "./css/Card.scss"

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register( BarElement, CategoryScale, LinearScale, Tooltip, Legend )

export default function TimelineCard({cardData, payload}) {
    // Only show data between certain hours for daily timeframe
    let xValuesTrimmed;
    let yValuesTrimmed;
    if (cardData.timeframe === "day" && typeof payload.xValues !== 'undefined' && payload.xValues.length > 0) {
        const startTime = 6;
        const endTime = 21;
        xValuesTrimmed = payload.xValues.slice(startTime, endTime);
        yValuesTrimmed = payload.yValues.slice(startTime, endTime);
    } else {
        xValuesTrimmed = payload.xValues;
        yValuesTrimmed = payload.yValues;
    }

    const data = {
        labels: xValuesTrimmed,
        datasets: [
            {
                label: 'weight [kg]',
                data: yValuesTrimmed,
                backgroundColor: '#0A86F9',
            }
        ]
    }
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        }
    }

    return (
        <div className="timelinecard-body">
            <Bar className = "timeline-chart"
                data = {data}
                options = {options}
            />
        </div>
    )
}