import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                title_count: 'Items Counted',
                title_weight: 'Total Weight (kg)',
                title_revenue: 'Environmental Cost (CHF)',
                title_timeline: "Material Gathered in kg",
                title_deployment_name: "Limmat - Monitoring Unit",
                subtitle_table: "Most recent detections",

                timeframe_title: "Set time frame",
                timeframe_today: "Today",
                timeframe_week: "Last Week",
                timeframe_month: "Last Month",
                timeframe_all: "All Time",

                navbar_hello: "Hi",

                table_time: "Time",
                table_date: "Date",
                table_objecttype: "Object Type",

                sidebar_dashboard: "Dashboard",
                sidebar_live: "Live View",
                sidebar_reporting: "Reporting",
                sidebar_invoicing: "Invoicing",
                sidebar_subscriptions: "Subscriptions",
                sidebar_bales: "Bale Overview",
                sidebar_trade: "Trade",

                loading: "Loading . . .",
                device_not_found_error: "Devices not found. Please refresh, or contact the UpCircle team. "
            }
        },
        de: {
            translation: {
                title_count: 'Anzahl Einheiten',
                title_weight: 'Gesamtgewicht (kg)',
                title_revenue: 'Umweltkosten (CHF)',
                title_timeline: "Material gesammelt in kg",
                title_deployment_name: "Limmat - Überwachungseinheit",
                subtitle_table: "Zuletzt erkannte Einheiten",

                timeframe_title: "Zeitraum festlegen",
                timeframe_today: "Heute",
                timeframe_week: "Letzte Woche",
                timeframe_month: "Letzter Monat",
                timeframe_all: "Gesamt",

                navbar_hello: "Hallo",

                table_time: "Zeit",
                table_date: "Datum",
                table_objecttype: "Objekttyp",

                sidebar_dashboard: "Dashboard",
                sidebar_live: "Live View",
                sidebar_reporting: "Bericht",
                sidebar_invoicing: "Fakturierung",
                sidebar_subscriptions: "Abonnements",
                sidebar_bales: "Ballen Übersicht",
                sidebar_trade: "Handel",

                loading: "Laden . . .",
                device_not_found_error: "Geräte nicht gefunden. Bitte aktualisieren Sie die Seite, oder kontaktieren Sie das UpCircle Team. "
            }
        },
    },
});

export default i18n;