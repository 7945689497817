import React from "react";
import "./css/Card.scss"
import { storage } from "../../context/FirebaseConfig";
import { ref, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";

import {useTable} from 'react-table'

export default function TableCard({cardData, payload}) {
    const { i18n, t } = useTranslation();

    const COLUMNS = [
        {
            Header: t("table_time"),
            accessor: 'time'
        },
        {
            Header: t("table_date"),
            accessor: 'date'
        },
        {
            Header: t("table_objecttype"),
            accessor: 'object_type'
        }
    ];

    const columns = React.useMemo(() => COLUMNS, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data:payload })

    const [imgUrl, setImgUrl] = React.useState("")
    function fetchImageUrl(firebase_url) {
        const imageRef = ref(storage, firebase_url)
        getDownloadURL(imageRef).then((url) => {
            setImgUrl(url)
        }).catch((error) => {
            console.log(error)
        })
    }
    if (payload !== undefined && payload.length > 0 && imgUrl === "") {
        fetchImageUrl(payload[0].img_url)
    }

    return(
        <div className="tablecard-body">
            <div className="table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => fetchImageUrl(row.original.img_url)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="example-image-wrapper">
                <img className="example-image" src={imgUrl} />
            </div>
        </div>  
    )
}