import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';

import "./i18n.ts";

// Importing styles
import './styles/index.css'
import './styles/style.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Any component wrapped in the "AuthContextProvider" will have access to the current user. Keeping it at top level here, will authenticate the entire app. 
  <AuthContextProvider> 
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthContextProvider>
);