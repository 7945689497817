import React from 'react';
import arc_logo from '../assets/logos/arc_logo_blue.png';
import arc_logo_dark from '../assets/logos/arc_logo_black.png';

const LoadingScreen = ({loading="Loading . . .", fullscreen=true, message=""}) => {
  return (
    <>
      {fullscreen ?
        <div className='loading-screen-fullscreen'>
          <div className='spinning-logo-container'>
            <img src={arc_logo} className="spinning-logo" />
          </div>
          <p>{loading}</p>
        </div>
        :
        <div className='loading-screen-small'>
          <div className='spinning-logo-container'>
            <img src={arc_logo_dark} className="spinning-logo" />
          </div>
          <p>{loading}</p>
          {message && <p>{message}</p>}
        </div>
      }
    </>
  )
}

export default LoadingScreen