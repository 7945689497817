import { createContext, useEffect, useState } from "react";
import { auth } from "./FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

// Setting up an app-wide user authentication provider. 
export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState({});

    useEffect(()=>{
        const unsub = onAuthStateChanged(auth, (user)=>{
            setCurrentUser(user);
            // // Debug if user exists 
            // console.log(user);
        });

        return () => {
            unsub();
        }

    }, []);

    return(
        // Any component wrapped into "authcontext provider" will be able to reach the current user. 
        // Import into index file to cover the whole application. 
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    );
};