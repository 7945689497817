import React, { useState} from 'react'
import upload_icon from '../assets/icons/upload_photo_icon_light_blue.png'
import {  createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, doc, setDoc, getDoc, query, orderBy, limit, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from "react-router-dom";
import arc_logo from '../assets/logos/arc_logo_blue.png';

export const Register = ({auth, storage, firestore}) => {
    const [err,setErr] = useState(false);
    // const usersRef = collection(firestore, "users");
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // add stateful component for file submission
    const [fileError, setFileError] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');

    const handleFileInvalid = (e) => {
        e.target.setCustomValidity('');
        if (!e.target.validity.valid) {
          e.target.setCustomValidity('Please choose a file');
        }
        setFileError(e.target.validationMessage);
        // If a file is selected, set its name to the state
        if (e.target.files[0]) {
          setUploadedFileName(e.target.files[0].name);
        }
      };

    // creating a handle for the form submission
    const handleSubmit = async (e) => {
        // setLoading(true);
        e.preventDefault();
        // console.log(e.target[2].value);
        const displayName = e.target[0].value;
        const email = e.target[1].value;
        const password = e.target[2].value;
        const rawCompany = e.target[3].value;
        const company = rawCompany.toLowerCase().replace(/\s+/g, ''); // converts to lowercase, and removes spaces. (e.g. UpCircle AI -> upcircleai)
        const language = e.target[4].value;
        const file = e.target[5].files[0];

        try{
            // check if company exists (for upcircle security purposes.)
            // Note, companies need to be manually added to Firebase for now. : under "clients"->"company_name", that's it. 
            // TODO: implement a "management" page to handle companies and users. 
            const companyRef = doc(firestore, "clients", company);
            const companySnap = await getDoc(companyRef);
            if (!companySnap.exists()) {
                // If the company does not exist, raise an error
                const error = new Error("The company does not exist.");
                setErr(error);
                throw(error);
            } else {
                // create user
                const response = await createUserWithEmailAndPassword(auth, email, password);
                
                // create unique image name, and store under `/profile_photos/
                const date = new Date().getTime();
                const folder = 'profile_photos/';
                const storageRef = ref(storage, `${folder+displayName+date}`);
                
                // Uploading profile photo to "firebase storage"
                await uploadBytesResumable(storageRef, file).then(() => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        try {
                            // update profile by AUTH provider
                            await updateProfile(response.user, {
                                displayName,
                                photoURL: downloadURL,
                            });
                            // create user in firestore "users" collection. 
                            await setDoc(doc(firestore, "users", response.user.uid), {
                                uid: response.user.uid,
                                displayName,
                                email, 
                                company,
                                photoURL: downloadURL,
                                status: "active", // options "active", "inactive"
                                createdAt: serverTimestamp(),
                                languageCode: language,
                            });

                            // Add user to the 'users' subcollection inside the specific company
                            const usersRef = collection(firestore, "clients", company, "users");
                            await addDoc(usersRef, {
                                uid: response.user.uid,
                                displayName,
                            });

                            // Once registered, navigate to login at end. 
                            navigate("/login");
                        } catch(err) {
                            console.log(err);
                            setErr(err);
                            // setLoading(false);
                        }
                    });
                });
            }

        } catch(err){
            setErr(err);
            console.log(err.code);
            console.log(err.message);
        }  
    };

  return (
    <div className="formContainer">
        <div className="formWrapper">
            <img className="logo" src={ arc_logo } alt="" />
            <span className='logo_caption'><span className='brand'>ARC</span> River Monitoring</span>
            <span className='title'>Register</span>
            <form onSubmit={handleSubmit}>
                <input required type="text" placeholder="display name"/>
                <input required type="email" placeholder="email"/>
                <input required type="password" placeholder="password"/>
                <input required type="company" placeholder="company" />
                <select required >
                    <option value="en" disabled selected>Preferred Language</option>
                    <option value="en">English</option>
                    <option value="de">Deutsch</option>
                </select>

                <input 
                    required 
                    style={{display:"none"}} 
                    type="file" 
                    id='register_file_upload'
                    onInvalid={handleFileInvalid}
                    onChange={handleFileInvalid}
                />
                <label htmlFor='register_file_upload'>
                    <img src={upload_icon} alt="upload_image_icon"/>
                    {uploadedFileName ? <span>Selected file: {uploadedFileName}</span> : <span>Add a profile photo</span>}
                </label>
                {fileError && <span className="error">{fileError}</span>}
                <button>Sign up</button>
                {err && err.code === 'auth/email-already-in-use' && <span className='error' >User already registered. Login instead.</span> }
                {err && err.message === 'The company does not exist.' && <span className='error'>Company is not registered. Please contact the UpCircle Team.</span>}

            </form>
            <p>Already registered? <Link to="/login">Login</Link></p>
        </div>
        <div className='upcircleFooter'>
            <span className='brand'>Powered by UpCircle</span>
            <span className='year'>&copy; 2023</span>
        </div>
    </div>
  )
}

export default Register;
