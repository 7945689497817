import "../styles/home.scss"
import "./Card/css/Card.scss"
import React from "react";
import { collection, onSnapshot, query, where, documentId, orderBy, limit} from "firebase/firestore";
import { firestore } from "../context/FirebaseConfig";
import { useTranslation } from "react-i18next";

import Card from './Card/Card';
import LoadingScreen from './LoadingScreen';

// Importing logos
import card_icon_green from '../assets/icons/gray/plastics_green.svg';
import card_icon_blue from '../assets/icons/gray/plastics_blue.svg';
import card_icon_yellow from '../assets/icons/gray/plastics_yellow.svg';
import card_icon_red from '../assets/icons/gray/plastics_red.svg';

export default function Insights({ timeframe, timeframeString, device }) {
    const { i18n, t } = useTranslation();

    const [itemsCounted, setItemsCounted] = React.useState(0)
    const [totalWeight, setTotalWeight] = React.useState(0)
    const [lostRevenue, setLostRevenue] = React.useState(0)
    const [tableData, setTableData] = React.useState([])
    const [timelineData, setTimelineData] = React.useState({})
    const [unsubscribe, setUnsubscribe] = React.useState(null);
    const detected_class = "litter" // TODO: remove hardcoded class name.

    React.useEffect(() => {
        const currentDate = new Date();
        const currentDateString = `${currentDate.getFullYear().toString().padStart(4, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

        // Create query for firebase download
        let firebaseQuery;
        if (timeframe === "day") {
            const dailyAnalyticsCollection = collection(firestore, "devices", device, "analytics", detected_class, "daily_analytics"); // TODO: remove hardcoded class name. 
            firebaseQuery = query(dailyAnalyticsCollection, where(documentId(), "==", currentDateString));
        } else {
            const analyticsCollection = collection(firestore, "devices", device, "analytics");
            firebaseQuery = query(analyticsCollection, where(documentId() , "==", detected_class));
        }

        // Unsubscribe from previous query
        if (unsubscribe) {
            unsubscribe();
        }

        // Subscribe to firebase live updates based on query
        const newUnsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
            if (snapshot.size == 0) {
                setItemsCounted(0);
                setTotalWeight(0);
                setLostRevenue(0);
                setTimelineData({xValues: [], yValues: []});
            } else {
                const doc = snapshot.docs[0].data();

                let totalCount = 0;
                let totalWeight = 0;
                let xValues = [];
                let yValues = [];

                if (timeframe === "day") {
                    xValues = [...Array(24).keys()].map((hour) => `${hour}:00`);
                    yValues = [...Array(24).fill(0)]
                    for (const hour of Object.keys(doc.hourly_count)) {
                        totalCount += doc.hourly_count[hour];
                        totalWeight += doc.hourly_weight_kg[hour];
                        yValues[hour] = doc.hourly_weight_kg[hour];
                    }
                } else {
                    const datesOrdered = Object.keys(doc.daily_count).sort();
                    const startDate = (
                        timeframe === "week" ? new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000) :
                        timeframe === "month" ? new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000) :
                        new Date(datesOrdered[0])
                    );

                    // Create empty entry for each day in the timeframe
                    let day = new Date(startDate.getTime());
                    while (day.getTime() <= currentDate.getTime()) {
                        xValues.push(day.toLocaleDateString("de-CH", {month: 'short', day: 'numeric'}));
                        yValues.push(0);
                        day.setDate(day.getDate() + 1);
                    }

                    // Fill in the data for each day available
                    for (const day of datesOrdered) {
                        const dayDate = new Date(day)
                        if (dayDate >= startDate) {
                            totalCount += doc.daily_count[day];
                            totalWeight += doc.daily_weight_kg[day];

                            yValues[xValues.indexOf(dayDate.toLocaleDateString("de-CH", {month: 'short', day: 'numeric'}))] = doc.daily_weight_kg[day];
                        }
                    }
                }

                setItemsCounted(totalCount);
                setTotalWeight(Math.round(totalWeight * 10) / 10);
                setLostRevenue(Math.round(totalWeight * 0.5 * 100) / 100);
                setTimelineData({xValues, yValues});
            }
        });
        setUnsubscribe(() => newUnsubscribe);

        //Get Last x Observations for Table
        const numObservations = 25;
        const observations = collection(firestore, "observations");
        const observationsQuery = query(observations, where("device", "==", device), orderBy(documentId(), 'desc'), limit(numObservations));
        onSnapshot(observationsQuery, (snapshot) => {
            let newTableData = []
            snapshot.forEach((doc) => {
                const date = new Date(doc.data().date)
                const formattedDate = date.toLocaleDateString("de-CH", {year: 'numeric', month: 'long', day: 'numeric'})
                newTableData.push({
                    "date":formattedDate,
                    "time":doc.data().time, 
                    "object_type":doc.data().object_type, 
                    "img_url":doc.data().image_url,
                    "weight":doc.data().weight
                })
            })
            setTableData(newTableData)
        })
    }, [timeframe])

    return (
        <div className="insights">
            <Card 
                cardData={{
                    ctype: 'BasicCard',
                    icon: <img src={card_icon_green} />,
                    title: t("title_count"),
                    subtitle: `Total, ${timeframeString}`,
                    count: itemsCounted
                }}
            />
            <Card 
                cardData={{
                    ctype: 'BasicCard',
                    icon: <img src={card_icon_blue} />,
                    title: t("title_weight"),
                    subtitle: `Total, ${timeframeString}`,
                    count: totalWeight
                }}
            />
            <Card
                cardData={{
                    ctype: 'BasicCard',
                    icon: <img src={card_icon_yellow} />,
                    title: t("title_revenue"),
                    subtitle: `Total, ${timeframeString}`,
                    count: lostRevenue
                }}
            />
            <Card 
                cardClassName="timelinecard"
                cardData={{
                    ctype: 'TimelineCard',
                    icon: <img src={card_icon_red} />,
                    title: t("title_timeline"),
                    subtitle: `Total, ${timeframeString}`,
                    timeframe: timeframe
                }}
                payload={timelineData}            
            />
            <Card
                cardClassName="tablecard"
                cardData={{
                    ctype: 'TableCard',
                    icon: <img src={card_icon_blue} />,
                    title: t("title_deployment_name"),
                    subtitle: t("subtitle_table")
                }}
                payload={tableData}
            />
        </div>
    )
}