// This file includes firebase resources and API, 
// exported to enable authentication and use across all other scripts. 

// firebase resources
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Firebase webapp configuration (PROJECT: DASHBOARD-ARC, NICKNAME: DASHBOARD)
const firebaseConfig = {
    apiKey: "AIzaSyBhK5IPITE2lC5FlEW_QqGbnSL00uJja80",
    authDomain: "dashboard-arc.firebaseapp.com",
    projectId: "dashboard-arc",
    storageBucket: "dashboard-arc.appspot.com",
    messagingSenderId: "871315651889",
    appId: "1:871315651889:web:25361e50d1a2ed27154410",
    measurementId: "G-0YLSXSEJJ6"
};

// initialize firebase and resources
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);


