import "./css/Card.scss"

export default function BasicCard({cardData}) {
    return(
        <div className="basiccard-body">
            <span className="counter">
                {cardData.count}
            </span>
        </div>  
    )
}